import React, { useState, useEffect, createElement } from "react";
import classes from './history.module.css';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import { registerLocale } from "react-datepicker";
import ApexCharts from 'apexcharts'
import Charts from "react-apexcharts"
import Select, { components } from 'react-select';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Table } from './table'

import { options1, options2, options3, options4, stylesSelect } from './options'

import "react-datepicker/dist/react-datepicker.css";
import 'react-loading-skeleton/dist/skeleton.css'
import ru from 'date-fns/locale/ru';

const SkeletonHolder = (
    <SkeletonTheme baseColor="rgba(31, 31, 31, 0.5)" highlightColor="rgba(60, 60, 60, 0.5)">
        <Skeleton height="100%" width="100%" />
    </SkeletonTheme>
)

export const HistoryPage = () => {
    const [Items, SetItems] = useState([]);
    const [profitSwitch, SetProfitSwitch] = useState(true);
    const [Charts, SetCharts] = useState(null);
    const [DatesButtonId, SetDatesButtonId] = useState(1);
    const [Loading, SetLoading] = useState(true);
    const [FilterAll, SetFilterAll] = useState(false);
    const [UserId, SetUserId] = useState(0);
    const [FirstSale, SetFirstSale] = useState(new Date(2020, 0, 1));
    const [TopIndices, SetTopIndices] = useState({profit_percent: "-", profit_cash: "-", sales: "-", volume: "-", round: "-"});
    const [withdraw_people, setWithdraw_people] = useState([]);
    const navigate = useNavigate();

    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    const date = new Date();
    // const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [startDate, setStartDate] = useState(new Date(date.getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        let chart1 = new ApexCharts(document.querySelector("#history_chart_sales"), options1);
        let chart2 = new ApexCharts(document.querySelector("#history_chart_profits"), options2);
        let chart3 = new ApexCharts(document.querySelector("#history_chart_sales_dates"), options3);
        let chart4 = new ApexCharts(document.querySelector("#history_chart_sales_duration"), options4);
        SetCharts({chart1: chart1, chart2: chart2, chart3: chart3, chart4: chart4})

        chart1.render();
        chart2.render();
        chart3.render();
        chart4.render();

        // LoadHistory(charts, dateStart, dateEnd);
        // setTimeout(() => {LoadHistory(charts, dateStart, dateEnd)}, 3000000)
        window.scrollTo(0, 0);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);

    }, [])

    async function DatesButton(e) {
        let id = e.target.id;
        const date = new Date();
        const dateEnd = new Date();
        setEndDate(dateEnd);
        if (id === "1") {
            const dateStart = new Date(date.getFullYear(), 0, 1);
            setStartDate(dateStart);
            SetDatesButtonId(1);
        }
        else if (id === "2") {
            const dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
            setStartDate(dateStart);
            SetDatesButtonId(2);
        }
        else if (id === "3") {
            const dateStart = new Date();
            setStartDate(dateStart);
            SetDatesButtonId(3);
        }
        else if (id === "4") {
            const dateStart = FirstSale;
            setStartDate(dateStart);
            SetDatesButtonId(4);
        }
    }

    async function LoadHistory(charts, dateStart, dateEnd, sort = "date_buy", direction="desc") {
        try {
            let acc_opt;
            let acc_flag = false;
            if (selectedOptionsAccounts.length !== 0){
                acc_opt = "&accounts=" + selectedOptionsAccounts.map((option) => option.value).join(",");
                acc_flag = true;
            }

            let markets_buy_opt;
            let markets_buy_flag = false;
            if (selectedOptionsMarketsBuy.length !== 0){
                markets_buy_opt = "&markets_buy=" + selectedOptionsMarketsBuy.map((option) => option.value).join(",");
                markets_buy_flag = true;
            }

            let r = await fetch(`https://artempos.ru/api/history?key=${Cookies.get("session")}&sort=${sort}&direction=${direction}&date_start=${dateStart.getTime()}&date_end=${dateEnd.getTime()}${acc_flag ? acc_opt : ''}${markets_buy_flag ? markets_buy_opt : ''}&all=${FilterAll ? "yes": "no"}`);
            r = await r.json();
            if (r.status) {

                let accounts = []
                r.data.accounts.forEach(account => {
                    accounts.push({value: account, label: account})
                });
                setOptionsAccounts(accounts);

                let markets_buy = []
                r.data.markets_buy.forEach(market_buy => {
                    markets_buy.push({value: market_buy, label: market_buy})
                });
                setOptionsMarketsBuy(markets_buy);

                SetItems([...r.data.items.sort((a, b) => {
                    let days_diff_a = Math.floor((a.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                    let days_diff_b = Math.floor((b.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                    if (a.timestamp_buy === null) {
                        if (days_diff_a < days_diff_b) return 1
                        else return -1
                    }
                    if (b.timestamp_buy === null) return 1
                    if (a.timestamp_buy === b.timestamp_buy) return 0
                    if (a.timestamp_buy > b.timestamp_buy) return -1
                    if (a.timestamp_buy < b.timestamp_buy) return 1
                })]);
                SetLoading(false);
                SetFirstSale(new Date(r.data.first_sale*1000))
                SetTopIndices({profit_percent: r.data.top.profit_percent, profit_cash: r.data.top.profit_cash, sales: r.data.top.sales, volume: r.data.top.volume, round: r.data.top.round})
                SetUserId(r.data.user_id)
                setWithdraw_people(r.data.withdraw_accounts)

                let max_volumes = 0;
                let volumes = r.data.volume.map((i) => {
                    if (i[0] > max_volumes) max_volumes = i[0];
                    return {x: i[2]*1000, y: i[0]}
                });
                let max_profits = 0;
                let profits = r.data.volume.map((i) => {
                    if (i[1] > max_profits) max_profits = i[1];
                    return {x: i[2]*1000, y: i[1]}
                });
                charts.chart1.updateSeries([
                    {name: "Объем продаж", data: volumes},
                    {name: "Прибыль", data: profits},
                ])  

                // charts.chart1.updateSeries([
                //     {name: "Объем продаж", data: data1},
                //     {name: "Прибыль", data: r.data.volume.profit}
                // ]);
                charts.chart1.updateOptions({
                    yaxis: [
                        {show: false, min: -max_volumes/2},
                        {show: false, max: max_profits*2, opposite: true}
                    ],
                })

                let percent_1 = r.data.percents.map((i) => {
                    return {x: i[2]*1000, y: i[0]}
                });
                let percent_2 = r.data.percents.map((i) => {
                    return {x: i[2]*1000, y: i[1]}
                });
                charts.chart2.updateSeries([
                    {
                        name: "Процент прогнозируемый",
                        data: percent_1
                    },
                    {
                        name: "Процент фактический",
                        data: percent_2
                    },
                ]);
                charts.chart3.updateSeries([
                    {
                        name: "Продажи",
                        data: r.data.sales
                    },
                    {
                        name: "Неуспешные продажи",
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    },
                ]);
                charts.chart4.updateSeries(r.data.duration);
            } else {
                if (r.error === "wrong session") navigate('/login', { replace: true });
                toast.error(r.error);
            }
        } catch (error) {
            toast.error("Не получилось выполнить запрос, ошибка: " + error);
        }
    }

    const [optionsAccounts, setOptionsAccounts] = useState([])
    const [selectedOptionsAccounts, setSelectedOptionsAccounts] = useState([])
    const [optionsMarketsBuy, setOptionsMarketsBuy] = useState([])
    const [selectedOptionsMarketsBuy, setSelectedOptionsMarketsBuy] = useState([])

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            let date1 = new Date(Math.min.apply(null, [startDate, endDate]))
            let date2 = new Date(Math.max.apply(null, [startDate, endDate]))
            date1.setHours(0, 0, 0)
            date2.setHours(23, 59, 59)

            SetLoading(true);
            SetTopIndices({profit_percent: "-", profit_cash: "-", sales: "-", volume: "-", round: "-"})
            
            if (Charts !== null) {
                LoadHistory(Charts, date1, date2);
            }
        }

    }, [Charts, FilterAll, startDate, endDate, selectedOptionsAccounts, selectedOptionsMarketsBuy, DatesButtonId])

    const onDatesChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start !== null && end !== null) {SetDatesButtonId(10);}
      };
    
    return (
      <main>
        <ToastContainer autoClose={2000} position='top-left' draggablePercent={40}/>
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <div className={classes.head}>
                <div className={classes.top}>
                    <div className={classes.calendar_holder}>
                        <span>Период:</span>
                        <div className={classes.calendar_buttons}>
                            <button id="4" onClick={DatesButton} className={DatesButtonId === 4 ? classes.active : ""}>Всё</button>
                            <button id="1" onClick={DatesButton} className={DatesButtonId === 1 ? classes.active : ""}>Год</button>
                            <button id="2" onClick={DatesButton} className={DatesButtonId === 2 ? classes.active : ""}>Месяц</button>
                            <button id="3" onClick={DatesButton} className={DatesButtonId === 3 ? classes.active : ""}>День</button>
                            <div style={{display: "flex", height: 26, width: 1, backgroundColor: "#828282", margin: "0 10px 0 6px"}}></div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => {SetDatesButtonId(10);setStartDate(date)}}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                minDate={FirstSale}
                                maxDate={date}
                                calendarClassName = "history_page_calendar"
                                // dayClassName={(date) => classes.calendar_days}
                                locale={ru}
                                dateFormat="dd/MM/yyyy"
                            />
                            <div style={{display: "flex", height: 1, width: 10, backgroundColor: "#828282", margin: "0 8px"}}></div>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => {SetDatesButtonId(10);setEndDate(date)}}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={FirstSale}
                                maxDate={date}
                                calendarClassName = "history_page_calendar"
                                // dayClassName={(date) => classes.calendar_days}
                                locale={ru}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        
                    </div>
                    <div className={classes.calendar_statistic}>
                        <span>Общие показатели:</span>
                        <div className={classes.statistic}>
                            <span>Прибыль %</span>
                            <span>{TopIndices.profit_percent}</span>
                        </div>
                        <div className={classes.statistic} style={{width: 85}}>
                            <span>Прибыль $</span>
                            <span>{TopIndices.profit_cash}</span> 
                        </div>
                        <div className={classes.statistic}>
                            <span>Продажи</span>
                            <span>{TopIndices.sales}</span>
                        </div>
                        <div className={classes.statistic}>
                            <span>Объем продаж</span>
                            <span>{TopIndices.volume}</span>
                        </div>
                        <div className={classes.statistic} style={{width: 85}}>
                            <span>Круг</span>
                            <span>{TopIndices.round}</span>
                        </div>
                    </div>
                    <div className={classes.accounts}>
                        <span>Фильтр:</span>
                        <Select
                            styles={stylesSelect} 
                            className="select-custom-class"
                            closeMenuOnSelect={false}
                            onChange={(opt) => {setSelectedOptionsAccounts(opt)}}
                            defaultValue={selectedOptionsAccounts}
                            options={optionsAccounts}
                            isMulti
                            maxMenuHeight={200}
                            components={{ 
                                NoOptionsMessage(props) {
                                    return (
                                    <components.NoOptionsMessage {...props}>
                                        <span>Больше нет аккаунтов</span> 
                                    </components.NoOptionsMessage>
                                    );
                                }
                            }}
                            placeholder={'Аккаунты'}
                        />
                        <button onClick={() => {SetFilterAll(!FilterAll)}} className={FilterAll ? classes.active : ""} style={UserId !== 1 ? {display: "None"} : {}}>Всё</button>
                        <Select
                            styles={stylesSelect} 
                            className="select-custom-class"
                            closeMenuOnSelect={false}
                            onChange={(opt) => {setSelectedOptionsMarketsBuy(opt)}}
                            defaultValue={selectedOptionsMarketsBuy}
                            options={optionsMarketsBuy}
                            isMulti
                            maxMenuHeight={200}
                            components={{ 
                                NoOptionsMessage(props) {
                                    return (
                                    <components.NoOptionsMessage {...props}>
                                        <span>Больше нет площадок</span> 
                                    </components.NoOptionsMessage>
                                    );
                                }
                            }}
                            placeholder={'Покупка'}
                        />
                    </div>
                </div>
                <div className={classes.graphs_holder}>
                    <DatePicker
                        selected={startDate}
                        onChange={onDatesChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        calendarClassName = "history_page_calendar history_page_calendar2"
                        dayClassName={(date) => classes.calendar_days}
                        locale={ru}
                        dateFormat="dd/MM/yyyy"
                    />
                    <div className={classes.chart_profits_holder}>
                        {Loading && SkeletonHolder}
                        
                        <div id="history_chart_profits">
                        </div>
                    </div>
                    <div className={classes.chart_sales_dates_holder}>
                        {Loading && SkeletonHolder}
                        <div id="history_chart_sales_dates">
                        </div>
                    </div>
                    <div className={classes.chart_sales_duration_holder}>
                        {Loading && SkeletonHolder}
                        <div id="history_chart_sales_duration">
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.chart_sales_holder}>
                {Loading && SkeletonHolder}
                <div id='history_chart_sales'></div>
            </div>
            <div className={classes.table_options_holder}>
                <span>История продаж</span>
                <div className={classes.table_options}>
                    <div className={classes.option} onClick={() => SetProfitSwitch(!profitSwitch)} action="percent/cash">%/$</div>
                </div>
            </div>
            <Table Items={Items} SetItems={SetItems} profitSwitch={profitSwitch} withdraw_people={withdraw_people} />
          </div>
        </div>
      </main>
    )
}