import React, { useEffect, useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import ReactApexChart from "react-apexcharts";
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Box } from '@mui/material';

import '../styles/finance.css';
import classes from "../styles/finance.module.css"

import icon_settings from "../images/icon_settings.png"
import icon_payments from "../images/icon_payments.png"
import icon_statistic from "../images/icon_statistic.png"
import arrow from "../images/arrow.png"
import logo_binance from "../images/logo_binance.png"
import logo_buff from "../images/logo_buff.png"
import logo_tm from "../images/logo_tm.png"

import Toggle from "./element_toggle"

const wallets = [
  { value: 1, label: "Youpin ордера", color: "#FFAE11" },
  { value: 1, label: "Youpin баланс", color: "#FFCC00" },
  { value: 1, label: "Binance", color: "#FAFF00" },
  { value: 1, label: "Bybit", color: "#E1A20F" },
  { value: 1, label: "Tm баланс", color: "#1598D0" },
  { value: 1, label: "Steam инвентарь", color: "#3E89C3" },
  { value: 1, label: "Lisskins", color: "#E8732E" },
]

const Finance = () => {
  const [Data, SetData] = useState({
    binance: { balance: 770, bordermin: 300, bordermax: 600 },
    buff: { balance: 2000, bordermin: 300, bordermax: 600 },
    budget: {
      array: wallets,
      summ: 0,
      count: 5,
      height: 3.5
    },
    top_statistic: [[0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0]],
    rates: [[98.33, "TM"], [7.33, "BUFF"]],
    budget_growth: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380, 1600, 2000],
  })

  const [open, setOpen] = React.useState(false);
  const [tooltipContent, setTooltipContent] = React.useState(null);
  const [tooltipPosition, setTooltipPosition] = React.useState([0, 0]);
  const [tooltipColor, setTooltipColor] = React.useState('red');

  const StyledText = styled('text')(({ theme }) => ({
    fill: "rgb(255, 255, 255)",
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 32,
    fontFamily: "'Inter', sans-serif"
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 1.35} y={280 / 2.05}>
        {children}
      </StyledText>
    );
  }

  let options = {
    series: [{
      name: "Бюджет",
      data: Data.budget_growth,
    }],
    options: {
      chart: {
        type: 'bar',
        // height: 350,
        // offsetY: -20,
        // offsetX: -5,
        parentHeightOffset: 0,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: true,
          distributed: true
        },
      },
      dataLabels: {
        enabled: false,
        offsetX: 0,
        style: {
          fontSize: '13px',
          fontWeight: 900
        }
      },
      legend: {
        show: false
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgb(255, 199, 0)', 'rgb(255, 199, 0)'],
        width: 1,
        dashArray: 4,
      },
      xaxis: {
        categories: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        labels: {
          style: {
            colors: "rgb(255, 255, 255)"
          },
          hideOverlappingLabels: true,
          formatter: function (value) {
            return value + "$";
          },
        },
        tickAmount: 3,
      },
      yaxis: {
        labels: {
          style: {
            colors: "rgb(255, 255, 255)"
          },
          offsetX: 10,
          formatter: function (value) {
            return String(value).slice(0, 3);
          },
        },
      },
      labels: {
        formatter: function (val) {
          return val + "$"
        }
      },
      grid: {
        padding: {
          top: -20,
          left: 0,
          right: 10,
          bottom: -5
        },
        yaxis: {
          lines: {
            show: false
          }
        },
      },
      tooltip: {
        x: {
          formatter: function (value) {
            return value;
          },
        },
        y: {
          formatter: function (value) {
            return value + "$";
          },
        },

      },
      colors: ['rgb(255, 199, 0)', 'rgb(255, 199, 0)', 'rgb(255, 199, 0)', 'rgb(255, 199, 0)', 'rgb(255, 199, 0)', 'rgb(255, 199, 0)', 'rgb(255, 199, 0)', 'rgb(255, 199, 0)', 'rgb(255, 199, 0)', 'rgb(255, 199, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)']
    },


  };


  const [BinanceSliderValue, SetBinanceSliderValue] = useState([Data.binance.bordermin, Data.binance.bordermax])
  const [BuffSliderValue, SetBuffSliderValue] = useState([Data.buff.bordermin, Data.buff.bordermax])

  const MinimumDistanceSlider = (event, newValue, activeThumb) => {
    if (!Array.isArray(BinanceSliderValue)) {
      return;
    }

    const minDistance = 50;

    if (activeThumb === 0) {
      SetBinanceSliderValue([Math.min(newValue[0], BinanceSliderValue[1] - minDistance), BinanceSliderValue[1]]);
    } else {
      SetBinanceSliderValue([BinanceSliderValue[0], Math.max(newValue[1], BinanceSliderValue[0] + minDistance)]);
    }
  }

  const MinimumDistanceSlider2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(BuffSliderValue)) {
      return;
    }

    const minDistance = 50;

    if (activeThumb === 0) {
      SetBuffSliderValue([Math.min(newValue[0], BuffSliderValue[1] - minDistance), BuffSliderValue[1]]);
    } else {
      SetBuffSliderValue([BuffSliderValue[0], Math.max(newValue[1], BuffSliderValue[0] + minDistance)]);
    }
  }

  function Open_group(e) {
    if (e.target.getElementsByTagName("img")[1].style.transform == "rotate(180deg)") {
      e.target.getElementsByTagName("img")[1].style.transform = "rotate(0deg)";
      e.target.parentNode.style.maxHeight = `50px`;
    }
    else {
      e.target.getElementsByTagName("img")[1].style.transform = "rotate(180deg)";
      var height = e.target.parentNode.scrollHeight;
      e.target.parentNode.style.maxHeight = `${height}px`;
    }
  }

  function TopStatisticScroll(e) {
    for (let i = 1; i <= 4; i++) {
      if (i !== 4) {
        if (e.target.parentNode.scrollTop >= (i - 1) * 77.5 / 2 && e.target.parentNode.scrollTop <= (i - 1) * 77.5 + 77.5 / 2) {
          e.target.parentNode.scrollTo({ top: (i - 1) * 77.5, behavior: "smooth" });
          return
        }
      }
      else {
        if (e.target.parentNode.scrollTop >= (i - 1) * 77.5 / 2 && e.target.parentNode.scrollTop <= (i - 1) * 77.5) {
          e.target.parentNode.scrollTo({ top: (i - 1) * 77.5, behavior: "smooth" });
          return
        }
      }
    }
  }
  function TopStatisticSwitch(number) {
    for (let i = 1; i <= 4; i++) {
      if (i === number) {
        document.querySelector(`.${classes.top_statistic_holder}`).scrollTo({ top: (i - 1) * 77.5, behavior: "smooth" });
        return
      }
    }
  }
  let TopStatisticCurrent = 4;

  useEffect(() => {
    function Fix() {
      if (Math.abs(document.querySelector(`.${classes.budget_container}`).getElementsByTagName("div")[0].getElementsByTagName("svg")[0].getElementsByTagName("g")[3].getElementsByTagName("g")[0].getElementsByTagName("rect")[0]?.getBoundingClientRect().top - document.querySelector(`.${classes.budget_container}`).getElementsByTagName("div")[0].getElementsByTagName("svg")[0].getElementsByTagName("g")[3].getElementsByTagName("g")[0].getElementsByTagName("text")[0].getBoundingClientRect().top) > 1) {
        // alert("offset");
        let elems = document.querySelectorAll(".MuiChartsLegend-series")
        for (var index = 0; index < elems.length; index++) {
          elems[index].getElementsByTagName("text")[0].setAttribute("y", "6.5");
          // elems[index].style.transform = `translateY(123px)`;
        }
        // let elems = document.querySelectorAll(".MuiChartsLegend-series")
        // for (var index = 0; index < elems.length; index++) {
        //     elems[index].setAttribute("transform", `translate(${elems[index].getAttribute("transform").split(" ")[0].replace("translate(", "")} ${parseFloat(elems[index].getAttribute("transform").split(" ")[1]) + 6.5})`);
        //     // elems[index].style.transform = `translateY(123px)`;
        // }
        // elems = document.querySelectorAll(".MuiChartsLegend-mark")
        // for (var index = 0; index < elems.length; index++) {
        //     elems[index].style.transform = "translateY(-6.5px)";
        // }
        // let elems = document.querySelectorAll(".MuiChartsLegend-mark")
        // for (var index = 0; index < elems.length; index++) {
        //     elems[index].nextSibling.style.transform = "translateY(6.5px)";
        // }
      }
    }
    setTimeout(() => {
      Fix();
      window.scrollTo(0, 0);
    }, 0)
    window.scrollTo(0, 0);
  }, [])

  async function LoadData() {
    fetch(`https://artempos.ru/api/budget?key=${Cookies.get("session")}`).then((response) => {
      if (response.status) {
        return response.json();
      }
      throw new Error('Something went wrong');
    }).then((r) => {
      if (r.status) {
        let summ = 0;
        let count = 0;
        let array = [];
        let height = 0;
        let options = {
          youpin_orders: { label: "Youpin ордера", color: "#FFAE11" },
          youpin_balance: { label: "Youpin баланс", color: "#FFCC00" },
          binance: { label: "Binance", color: "#FAFF00" },
          bybit: { label: "Bybit", color: "#E1A20F" },
          tm: { label: "Tm баланс", color: "#1598D0" },
          steam: { label: "Steam инвентарь", color: "#3E89C3" },
          lisskins: { label: "Lisskins", color: "#E8732E" }
        };
        for (const key in r.data.budget) {
          summ += r.data.budget[key];
          if (r.data.budget[key] !== 0) {
            count += 1;
            if (key === "steam" || key === "youpin_orders") {
              height += 1;
            } else {
              height += 0.5;
            }
            array.push({ value: r.data.budget[key], label: options[key].label, color: options[key].color });
          };
        }
        SetData({
          ...Data, budget: {
            array: array,
            summ: summ.toFixed(3),
            count: count,
            height: Math.round(height)
          },
          top_statistic: r.data.top_statistic,
          rates: r.data.rates
        });
      } else {
        // toast.error(r.error)
      }
    }).catch((error) => { toast.error("Не получилось выполнить запрос, ошибка: " + error) });
  }

  useEffect(() => {
    LoadData()

    let elems = document.querySelectorAll(`.${classes.top_statistic}`)
    for (let i = 0; i < elems.length; i++) {
      elems[i].addEventListener('click', ((e) => { Number(e.target.getAttribute("id")) !== 4 ? TopStatisticSwitch(Number(e.target.getAttribute("id")) + 1) : TopStatisticSwitch(1) }));
    }
    // document.querySelector(`.${classes.top_statistic_holder}`).addEventListener('scroll', ((e) => {console.log(e.target.scrollTop)}));
    document.querySelector(`.${classes.top_statistic_holder}`).addEventListener('scrollend', ((e) => { e.preventDefault(); }));
    // document.querySelector(`.${classes.top_statistic_holder}`).addEventListener('scrollend', TopStatisticScroll);
    document.querySelector(`.${classes.top_statistic_holder}`).addEventListener('touchend', TopStatisticScroll);
    document.querySelector(`.${classes.top_statistic_holder}`).addEventListener('scroll', ((e) => { e.preventDefault(); }), { passive: false });

    TopStatisticSwitch(TopStatisticCurrent);
    // console.log(document.querySelectorAll(`.${classes.group_head}`)[1].click())
  }, [])

  let TopStatisticElements = [];
  const month_label = { 1: "Г", 2: "М", 3: "Н", 4: "Д" };
  for (let i = 1; i <= 4; i++) {
    let points = []
    for (let k = 1; k <= 4; k++) {
      if (k !== i) points.push(<div className={classes.count} key={k}></div>)
      else points.push(<div className={[classes.count, classes.current].join(" ")} key={k}></div>)
    }
    TopStatisticElements.push(
      // <div className={classes.top_statistic} style={{transform: `translateY(${(i-current_element)*80}px)`}} key={i}>
      <div className={`${classes.top_statistic} ${classes.holders_color}`} key={i} id={i} style={i === 1 ? { marginTop: 0 } : {}}>
        <div className={classes.counts}>
          {points}
        </div>

        <div className={classes.top_statistic_group}>
          <div className={classes.top_statistic_statistic}>
            <span style={{ fontSize: 22, fontWeight: 500 }}>{Data.top_statistic[i - 1][0]}$</span>
            <span style={{ fontSize: 12, fontWeight: 300, color: "#BFBFBF", marginTop: -4 }}>Прибыль</span>
          </div>
          <div className={classes.border}></div>
          <div className={classes.top_statistic_statistic}>
            <span style={{ fontSize: 22, fontWeight: 500 }}>{Data.top_statistic[i - 1][1]}%</span>
            <span style={{ fontSize: 12, fontWeight: 300, color: "#BFBFBF", marginTop: -4 }}>Процент прибыли</span>
          </div>
          <div className={classes.border}></div>
          <div className={classes.top_statistic_statistic}>
            <span style={{ fontSize: 22, fontWeight: 500 }}>{Data.top_statistic[i - 1][2]}</span>
            <span style={{ fontSize: 12, fontWeight: 300, color: "#BFBFBF", marginTop: -4 }}>Продажи</span>
          </div>
          <div className={classes.top_statistic_statistic} style={{ position: "absolute", top: 0, right: 15 }}>
            <span style={{ fontSize: 16, fontWeight: 600 }}>{month_label[i]}</span>
          </div>
        </div>

      </div>
    )
  }

  let count = 0;
  let rates = Data.rates.map((el) => {
    count += 1;
    return <div className={classes.rate} style={count === 1 ? { paddingLeft: 8 } : {}}>
      <div className={classes.number}>{el[0]}</div>
      <div className={classes.market}>{el[1]}</div>
    </div>
  })

  const BinanceTheme = createTheme({
    palette: {
      primary: {
        main: "#F3BA2F"
      }
    }
  })

  const handleClick = (event, data1, data2) => {
    console.log(data1)
    setTooltipContent(`${data2.label}: ${data2.value}$`);
    setOpen(true);
    setTooltipPosition([event.pageX, event.pageY])

    console.log(wallets.map((item) => {
      if (item.label === data2.label) setTooltipColor(data2.color)
    }))
    
  };

  function ClickHandler(e) {
    if (!e.target.classList.contains("MuiPieArc-root")) setOpen(false);
  }

  useEffect(() => {
    document.addEventListener("click", ClickHandler)
  }, [])


  return (
    <main>
      <div className={classes.group} style={{ maxHeight: 660 }}>
        <div className={classes.statistic}>
          <div className={classes.top_statistic_holder}>
            {TopStatisticElements}
          </div>
          <div className={classes.statistic_left}>
            <div className={`${classes.budget} ${classes.holders_color}`}>
              <div className={classes.section_head} style={{ marginBottom: 10 }}>
                <p>Бюджет аккаунта</p>
              </div>
              <div className={classes.budget_container}>
                <PieChart
                  series={[{
                    data: Data.budget.array,
                    cx: 145,
                    cy: 130,
                    innerRadius: 105,
                    outerRadius: 130,
                    paddingAngle: 1,
                  }]}
                  width={300}
                  height={280 + Math.round(Data.budget.height) * 30}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { vertical: 'bottom', horizontal: 'middle' },
                      padding: 0,
                      labelStyle: {
                        fill: "white",
                        fontFamily: "'Inter', sans-serif",
                        fontSize: 18,
                      },
                      // itemMarkHeight: 10,
                      offsetY: 100,
                    },
                    // pieArc: {
                    //   onClick: (event, {dataIndex, seriesId, value}) => {console.log(dataIndex)} ,
                    //   cursor: "pointer"
                    // },
                  }}
                  onItemClick={handleClick}
                  tooltip={{
                    trigger: 'none',
                  }}
                  sx={{
                    '& .MuiPieArc-root': {
                      stroke: "rgb(46, 46, 46)",
                    },
                  }}
                >
                  <PieCenterLabel>{Data.budget.summ}$</PieCenterLabel>
                </PieChart>
                {open && (
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'absolute',
                      top: `${tooltipPosition[1]}px`,
                      left: `${tooltipPosition[0]}px`,
                      // transform: 'translate(-50%, -50%)',
                      backgroundColor: 'rgb(44, 44, 44)',
                      color: '#FFFFFF',
                      border: '1px solid #999',
                      padding: '8px',
                      borderRadius: '4px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      fontSize: 14,
                      zIndex: 10000,
                    }}
                  >
                    <Box width={16} height={16} backgroundColor={tooltipColor} mr={1} />
                    {tooltipContent}
                  </Box>
                )}
              </div>
            </div>
            <div className={`${classes.rates} ${classes.holders_color}`}>
              <div className={classes.section_head}>
                <p>Курсы валют</p>
              </div>
              {rates}
            </div>
          </div>
          <div className={`${classes.statistic_right} ${classes.holders_color}`}>
            <div className={classes.section_head}>
              <p>Динамика бюджета</p>
            </div>
            <div className={classes.budget_container}>
              <div id="chart_budget">
                <ReactApexChart options={options.options} series={options.series} type="bar" height="100%" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Finance;