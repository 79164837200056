import axios from 'axios'
import { notification } from 'antd';

export const api = axios.create({
  baseURL: "https://artempos.ru/api",
  withCredentials: true,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiFetchData = async (config) => {
  return await api
    .request(config)
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      throw e
    })
}

export const withPublicAPIToken = async (config) => {
  return await apiFetchData({ ...config })
    .then((res) => {
      return res
    })
    .catch((e) => {
      console.error('ошибка public запроса', e)
      if (e.status == 401) {
        // notification.error({
        //   message: 'Ошибка',
        //   description: 'Необходимо заново авторизоваться.',
        //   duration: 3,
        // });  
      } else {
        notification.error({
          message: 'Ошибка',
          description: 'Не удалось выполнить запрос, попробуйте еще раз.',
          duration: 3,
        });
      }
      
      throw e
    })
}

export default api;