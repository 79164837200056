import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { theme } from './theme'
import { ThemeProvider } from '@mui/material';
import './styles/index.css'

import App from './components/app';
const mountNode = document.getElementById('root');
const root = ReactDOM.createRoot(mountNode);

root.render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>
); 