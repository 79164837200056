import React from 'react';
import { SvgIcon } from '@mui/material';

export const WeaponIcon = (props) => (
    <SvgIcon version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet" {...props}>
       <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
            <path d="M4500 5005 l-115 -115 -77 77 -78 78 -108 -108 -107 -107 78 -77 77
            -78 -1942 -1942 -1943 -1943 395 -395 c303 -303 397 -392 406 -382 6 6 168
            272 359 590 l348 579 91 -169 c50 -94 152 -283 226 -421 74 -138 137 -252 140
            -252 3 0 131 125 284 279 l279 279 -107 198 -107 198 158 158 158 159 -132
            258 -131 258 56 56 57 57 195 -195 195 -195 250 250 250 250 -195 195 -195
            195 315 315 315 315 -150 150 -150 150 618 618 617 617 -108 108 -107 107
            -115 -115z m-1964 -3319 c4 -13 -6 -31 -37 -62 -24 -24 -46 -44 -49 -44 -5 0
            -77 127 -114 202 -8 15 0 28 39 68 l49 50 53 -98 c29 -53 56 -105 59 -116z"/>
            <path d="M1940 3465 l-104 -105 39 -40 39 -40 -534 -535 -535 -535 -40 40 -41
            40 -107 -108 -107 -107 190 -190 190 -190 107 107 108 108 -43 43 -42 42 535
            535 535 535 43 -43 43 -42 102 103 c56 56 102 107 102 112 0 10 -361 375 -370
            375 -3 0 -52 -47 -110 -105z"/>
        </g>
    </SvgIcon>
)