import { createTheme, Theme } from '@mui/material/styles'
import palette from './palette'

const {
  breakpoints: { down, up },
} = createTheme()

export const theme: Theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 600, md: 900, lg: 1440, xl: 1920 } },
  palette,
  // typography: {
  //   "fontFamily": 'Manrope',
  // },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '6px',
          color: palette.primary.main,
          '&:hover': {
            backgroundColor: 'rgb(107, 109, 112)',
          },
          '&.Mui-selected': {
            backgroundColor: '#E5E7ED',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#D8DBE1',
          },
        },
      },
    },
  }
})
