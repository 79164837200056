import React, { useState, useEffect, useRef } from "react";
import Cookies from 'js-cookie';
import { notification } from 'antd';
import Gradient from 'javascript-color-gradient';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Icon,
  SvgIcon
} from '@mui/material';
import classes from './history.module.css';
import sorter from '../../images/sorter.png'
import img_arrow from "../../images/arrow.png"
import { historyStyles } from './history.styles'
import api from '../api/api'
import { svgs } from '../../images'
import { WeaponIcon } from '../../images/history/weapon'

export const Table = ({ Items, SetItems, profitSwitch, withdraw_people }) => {
  const gradentParams = ['#FF0000', '#EBFF00', 'BDFF00', '#90EE90', '#00FFB2'];
  
  const [percentExpand, setPercentExpand] = useState(0);
  const [withdrawExpand, setWithdrawExpand] = useState(0);
  const [currentColumn, setCurrentColumn] = useState("date_buy");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  function Sort(id) {
    if (id === currentColumn) {
      if (id === "name") SetItems([...Items.sort((a, b) => a.name < b.name ? 1 : -1)]);
      else if (id === "date_buy") SetItems([...Items.sort((a, b) => {
        let days_diff_a = Math.floor((a.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
        let days_diff_b = Math.floor((b.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
        if (a.timestamp_buy === null) {
            if (days_diff_a > days_diff_b) return 1
            else return -1
        }
        if (b.timestamp_buy === null) return 1
        if (a.timestamp_buy === b.timestamp_buy) return 0
        if (a.timestamp_buy > b.timestamp_buy) return 1
        if (a.timestamp_buy < b.timestamp_buy) return -1
      })]);
      else if (id === "date_sell") SetItems([...Items.sort((a, b) => a.timestamp_sell > b.timestamp_sell ? 1 : -1)]);
      else if (id === "price_buy") SetItems([...Items.sort((a, b) => a.price_buy < b.price_buy ? 1 : -1)]);
      else if (id === "price_sell") SetItems([...Items.sort((a, b) => a.price_sell < b.price_sell ? 1 : -1)]);
      else if (id === "profit") {
        if (profitSwitch) SetItems([...Items.sort((a, b) => a.profit_cash < b.profit_cash ? 1 : -1)]);
        else SetItems([...Items.sort((a, b) => a.profit_percent < b.profit_percent ? 1 : -1)]);
      } 
      else if (id === "account") SetItems([...Items.sort((a, b) => a.account < b.account ? 1 : -1)]);
      else if (id === "market_buy") SetItems([...Items.sort((a, b) => a.market_buy < b.market_buy ? 1 : -1)]);
      else if (id === "market_sell") SetItems([...Items.sort((a, b) => {
        if (a.market_sell === b.market_sell) return 0
        if (a.market_sell < b.market_sell) return 1
        if (a.market_sell > b.market_sell) return -1
        if (a.market_sell === null) return -1
        if (b.market_sell === null) return 1
      })]);
      setCurrentColumn("");
    } else {
      if (id === "name") SetItems([...Items.sort((a, b) => a.name > b.name ? 1 : -1)]);
      else if (id === "date_buy") SetItems([...Items.sort((a, b) => {
        let days_diff_a = Math.floor((a.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
        let days_diff_b = Math.floor((b.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
        if (a.timestamp_buy === null) {
            if (days_diff_a < days_diff_b) return 1
            else return -1
        }
        if (b.timestamp_buy === null) return 1
        if (a.timestamp_buy === b.timestamp_buy) return 0
        if (a.timestamp_buy > b.timestamp_buy) return -1
        if (a.timestamp_buy < b.timestamp_buy) return 1
      })]);
      else if (id === "date_sell") SetItems([...Items.sort((a, b) => a.timestamp_sell < b.timestamp_sell ? 1 : -1)]);
      else if (id === "price_buy") SetItems([...Items.sort((a, b) => a.price_buy > b.price_buy ? 1 : -1)]);
      else if (id === "price_sell") SetItems([...Items.sort((a, b) => a.price_sell > b.price_sell ? 1 : -1)]);
      else if (id === "profit") {
        if (profitSwitch) SetItems([...Items.sort((a, b) => a.profit_percent > b.profit_percent ? 1 : -1)]);
        else SetItems([...Items.sort((a, b) => a.profit_cash > b.profit_cash ? 1 : -1)]);
      }
      else if (id === "account") SetItems([...Items.sort((a, b) => a.account > b.account ? 1 : -1)]);
      else if (id === "market_buy") SetItems([...Items.sort((a, b) => a.market_buy > b.market_buy ? 1 : -1)]);
      else if (id === "market_sell") SetItems([...Items.sort((a, b) => {
          if (a.market_sell === b.market_sell) return 0
          if (a.market_sell > b.market_sell) return 1
          if (a.market_sell < b.market_sell) return -1
          if (a.market_sell === null) return 1
          if (b.market_sell === null) return -1
      })]);
      setCurrentColumn(id);
    }
  }
  
  function Sort_handler(e) {
    const id = e.currentTarget.getAttribute("column");
    Sort(id)
  }

  const WithdrawSkin = async (item_id, account) => {
    try {
      const queryParams = new URLSearchParams({
        key: Cookies.get("session"),
        item_id: item_id,
        account: account,
      }).toString();
    
      const response = await api.get(`/withdraw_skin?${queryParams}`);
      const data = response.data

      if (data.status) {
          notification.success({
          message: 'Успешно',
          description: response.data.message,
          duration: 3,
        });  
      } else if (!data.status) {
        notification.error({
        message: 'Ошибка',
        description: response.data.error,
        duration: 3,
      });  
    }

      console.log(data)
    } catch (error) {
      console.error('Ошибка при запросе истории чата:', error.message);
    }  
  }

  function ClickHandler(e) {
    if (e.target.id !== "profit_expander") setPercentExpand(0)
    if (e.target.id !== "withdraw_expander") setWithdrawExpand(0)
  }

  useEffect(() => {
    document.addEventListener("click", ClickHandler)
  }, [])

  const colorCellFromValue = (params, value, midpoint = 29) => {
    value += 2;
    if (value <= 0) {
        value = 1;
    }
    return new Gradient()
      .setColorGradient(...params)
      .setMidpoint(midpoint).getColor(value);
  };

  let percent_flag = false

  const items = Items.map((item) => {
    let date_buy_value
    let date_buy_color
    let days_diff = 1

    if (item.date_buy) {
      date_buy_value = item.date_buy
      date_buy_color = "rgb(255, 255, 255)"
    } else {
      date_buy_color = "rgb(156, 156, 156)"

      if (item.time_buy_tradeban) {
        days_diff = Math.floor((item.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
        
        date_buy_value = `${item.time_buy_tradeban} (${days_diff})`
      } else {
        date_buy_value = '-'
      }
    }

    return (
      <Box component="tr" className={classes.item} key={item.id}>
        <th style={{textAlign: "left", paddingLeft: 0}}>{item.name}</th>
        <Box component="td" style={{ color: date_buy_color }}>
          <Box width="min-content" style={{ position: 'relative' }}>
            {date_buy_value}
            {days_diff === 0 && (
              <>
                <IconButton sx={historyStyles.test} aria-describedby={id} onClick={() => setWithdrawExpand((old_id) => old_id === item.id ? 0 : item.id)}>
                  <img id="withdraw_expander" style={{ height: '20px', filter: 'invert(100%)', transform: 'rotate(180deg)' }} src={img_arrow} />
                </IconButton>
                {withdrawExpand === item.id && (
                  <Box sx={historyStyles.withdrawHolder}>
                    <List>
                      {withdraw_people.sort((a, b) => a.amount - b.amount).map((person, index) => (
                      <ListItem disablePadding key={index}>
                        <ListItemButton onClick={() => WithdrawSkin(item.id, person.name)}>
                          <ListItemText primary={person.name} />
                          <Box sx={historyStyles.withdrawInfo}>
                            <svgs.WeaponIcon sx={{ color: 'primary.dark' }} />
                            {person.amount}
                          </Box>
                          </ListItemButton>
                      </ListItem>  
                      ))}
                    </List>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
        <td>{item.date_sell ? item.date_sell : "-"}</td>
        <td>{item.price_buy}{item.currency_buy === "USD" ? "$" : "¥"}</td>
        <td style={{ opacity: item.date_sell ? 1 : 0.4 }}>{item.price_sell ? item.currency_sell === "USD" ? item.price_sell+"$" : item.price_sell+"¥" : "-"}</td>
        <td style={{position: 'relative'}}>
          <div
            id="profit_expander"
            style={
              {
                display: 'flex',
                width: 'fit-content',
                cursor: 'pointer',
                minWidth: '0',
                boxSizing: 'border-box',
                alignItems: 'center',
                color: item.profit_percent !== null ? colorCellFromValue(gradentParams, item.profit_percent) : '',
                opacity: item.date_sell ? 1 : 0.4,
                transition: 'opacity 0.3s, background-color 0.3s, border-radius 0.3s',
              }
            }
            className={item.id === percentExpand ? classes.percent_active : ''}
            onClick={() => {setPercentExpand(item.id)}}
          >
            {
              item.id === percentExpand && (
                item.market_profits.sort((a, b) => a.profit_percent > b.profit_percent ? 1 : -1).sort((a, b) => a.important === false ? 1 : -1).map((el) => {
                  if (!el.important && percent_flag === false) {
                    percent_flag = true
                    return (
                      <>
                        <div style={{ width: '100%', height: '1px', backgroundColor: '#9C9C9C', padding: '0', margin: '4px 0' }}></div>
                        <div style={{ color: '#FFFFFF' }}><span style={{color: colorCellFromValue(gradentParams, el.profit_percent)}}>{el.profit_percent}%</span><span> - {el.market}</span></div>
                      </>                                        
                    )
                  } else {
                    return <div style={{ color: '#FFFFFF' }}><span style={{color: colorCellFromValue(gradentParams, el.profit_percent)}}>{el.profit_percent}%</span><span> - {el.market}</span></div>
                  }
                })
              )
            }{
              item.id !== percentExpand && (
                item.profit_percent !== null ? profitSwitch ? item.profit_percent + "%" : item.currency_sell === "USD" ? item.profit_cash + "$": item.profit_cash + "¥" : "-" 
              )
            }{
              item.id !== percentExpand && <img id="profit_expander" style={{ height: '20px', filter: 'invert(100%)', transform: 'rotate(180deg)' }} src={img_arrow} />
            }
          </div>
        </td>
        <td>{item.account}</td>
        <td>{item.market_buy ? item.market_buy : "-"}</td>
        <td style={{paddingRight: 0}}>{item.market_sell ? item.market_sell : "-"}</td>
      </Box>
    )
  })

  return (
    <div className={classes.table_holder}>
      <table className={classes.table}>
        <thead className={classes.thead}>
          <tr>
            <th column="name" onClick={Sort_handler} style={{textAlign: "left", paddingLeft: 0}}><span>Название</span><img src={sorter} className={classes.sorter}/></th>
            <th column="date_buy" onClick={Sort_handler}><span>Дата покупки</span><img src={sorter} className={classes.sorter}/></th>
            <th column="date_sell" onClick={Sort_handler}><span>Дата продажи</span><img src={sorter} className={classes.sorter}/></th>
            <th column="price_buy" onClick={Sort_handler}><span>Покупка</span><img src={sorter} className={classes.sorter}/></th>
            <th column="price_sell" onClick={Sort_handler}><span>Продажа</span><img src={sorter} className={classes.sorter}/></th>
            <th column="profit" onClick={Sort_handler}><span>Прибыль</span><img src={sorter} className={classes.sorter}/></th>
            <th column="account" onClick={Sort_handler}><span>Аккаунт</span><img src={sorter} className={classes.sorter}/></th>
            <th column="market_buy" onClick={Sort_handler}><span>Куплен</span><img src={sorter} className={classes.sorter}/></th>
            <th column="market_sell" style={{paddingRight: 0}} onClick={Sort_handler}><span>Продан</span><img src={sorter} className={classes.sorter}/></th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </table>
    </div>
  )
  }
