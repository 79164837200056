import { SxProps, Theme } from '@mui/material'

export const historyStyles: Record<string, SxProps<Theme> | undefined> = {
  withdrawHolder: ({ breakpoints: { down } }: Theme) => ({
    width: '100vw',
    maxWidth: '200px',
    position: 'absolute',
    backgroundColor: '#303030',
    borderRadius: 2,
    zIndex: 1,
    right: 0,
  }),
  withdrawInfo: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    color: palette.primary.dark,
    '& img': {
      width: 20,
    }
  }),
  test: ({ breakpoints: { down } }: Theme) => ({
    '&.MuiButtonBase-root': {
      padding: '0',
    }
  }),
};